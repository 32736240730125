<h1 mat-dialog-title>
  {{data.title}}
</h1>

<mat-dialog-content>
  <p>{{data.message}}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onCancel()" color="{{data.cancelColor}}">{{data.cancelText}}</button>
  <button mat-raised-button (click)="onConfirm()" color="{{data.confirmColor}}">{{data.confirmText}}</button>
</mat-dialog-actions>
